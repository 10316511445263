import React, { Component } from "react";
import { makeStyles, createStyles, withStyles } from "@material-ui/core/styles";
import "../../../App.css";

import anmeldung from "../../../assets/mobile/anmeldung.png";
import auszubildende from "../../../assets/mobile/auszubildende.png";
import dental from "../../../assets/dental_quadrat.jpg";
import verwaltung from "../../../assets/verwaltung_quadrat.jpg";
import prophylaxe from "../../../assets/mobile/prophylaxe.jpg";
import zahnaerzte from "../../../assets/zahnaerzte_quadrat.jpg";
import assistentinnen from "../../../assets/mobile/assistentinnen.png";

import { styles } from "../../../breakpoints";

import Divider from "@material-ui/core/Divider";
import { Helmet } from "react-helmet";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

export const useStyles = makeStyles(() =>
  createStyles({
    root: {
      zIndex: "1500 !important",
    },
    paper: {
      width: "100%",
      overflow: "hidden",
      margin: 16,
    },
    accordion: {
      "&::before": {
        height: 0,
      },
      margin: "0px !important",
    },
  })
);

function GridItem(props) {
  const { element } = props;
  const classes = useStyles();

  return (
    <div
      className="content"
      style={{ marginBottom: 40, backgroundColor: "#ededed" }}
    >
      <img style={{ width: "100%" }} src={element.img} />

      <Accordion
        classes={{ root: classes.accordion }}
        style={{
          height: "fit-content",
          gridColumnEnd: "span 1",
          position: "relative",
          boxShadow: "none",
          backgroundColor: "#ededed",
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon style={{ color: "rgb(19, 41, 75)" }} />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography
            component="span"
            style={{
              fontWeight: "bold",
              fontSize: "1.4rem",
              color: "rgb(19, 41, 75)",
            }}
          >
            {element.title}
            <Divider
              style={{
                height: 3,
                width: "75%",
                backgroundColor: "rgb(142, 66, 50)",
                marginBottom: 10,
              }}
            />
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          style={{ display: "block", backgroundColor: "#ededed" }}
        >
          <Typography
            component="span"
            style={{ textAlign: "justify", color: "#000" }}
          >
            <div style={{ textAlign: "justify" }}>{element.text}</div>
            <br />
            {element.names}
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

const elements = [
  {
    key: "Zahnärzte",
    title: "Unsere Zahnärzte",
    names: "",
    text: (
      <>
        Mit den{" "}
        <span style={{ fontWeight: "bold" }}>
          Dres. med. dent. Katharina Link-Löffler
        </span>
        , <span style={{ fontWeight: "bold" }}>Sebastian</span> und{" "}
        <span style={{ fontWeight: "bold" }}>Paulina Link</span> ist nach{" "}
        <span style={{ fontWeight: "bold" }}>ZA Michael Link</span> die zweite
        Generation in Krombach angekommen. Wir freuen uns auf weitere 40 Jahre
        mit Ihnen!{" "}
      </>
    ),
    img: zahnaerzte,
  },
  {
    key: "Anmeldung",
    title: "Unsere Anmeldung",
    names: (
      <div>
        {" "}
        <span style={{ fontWeight: "bold" }}>
          Mechthild Schürmann-Link
        </span> und <span style={{ fontWeight: "bold" }}>Lisa Geis</span>{" "}
      </div>
    ),
    text: "Um Ihren Aufenthalt schon von Beginn an so angenehm wie möglich zu gestalten, empfangen wir Sie bereits an der Anmeldung mit einem herzlichen Lächeln. Bei Fragen und Terminvereinbarung sind wir hier für Sie da. Helfen Sie uns, indem Sie Adress- und Datenänderungen bekannt geben.",
    img: anmeldung,
  },
  {
    key: "Assistentinnen",
    title: "Unsere Assistentinnen",
    names: (
      <div>
        {" "}
        <span style={{ fontWeight: "bold" }}>Joline Augschöll</span> und{" "}
        <span style={{ fontWeight: "bold" }}> Anna-Lena Mehling</span>{" "}
      </div>
    ),
    text: "... sorgen für eine angenehme und reibungslose Behandlung. Sie sind unsere empathischen Allrounder.",
    img: assistentinnen,
  },

  {
    key: "Prophylaxe",
    title: "Unser Prophylaxeteam",
    names: (
      <div>
        {" "}
        <span style={{ fontWeight: "bold" }}>
          Martina Bathon, Heike Heeg
        </span>{" "}
        und <span style={{ fontWeight: "bold" }}>Larissa Schmidt</span>
      </div>
    ),
    text: "Unser fortgebildetes Prophylaxeteam kümmert sich um vorbeugende Maßnahmen und die gründliche Reinigung Ihrer Zähne. Gleichzeitig motivieren wir Sie und geben nützliche Tipps für zuhause. Unsere zahnmedizinische Prävention sorgt für eine anhaltende Stärkung der Zähne und lässt Ihr Lächeln erstrahlen.",
    img: prophylaxe,
  },
  {
    key: "Dentalhygiene",
    title: "Unsere Dentalhygienikerin",
    names: (
      <div>
        {" "}
        <span style={{ fontWeight: "bold" }}>DH Anna Amberg (BA)</span>{" "}
      </div>
    ),
    text: "Eine dauerhafte Zahnfleischentzündung kann zu einer Parodontitis, d.h. zu einer Entzündung des Zahnhalteapparates bis hin zum Zahnverlust, führen. Um ein Fortschreiten der Erkrankung bestmöglich zu verhindern, steht Ihnen Anna Amberg zur Seite. Anna Amberg berät Sie auch gerne rund um das Thema Bleaching (Zahnaufhellung), um Ihre Zähnen schonend aufzuhellen.",
    img: dental,
  },
  {
    key: "Auszubildenden",
    title: "Unsere Auszubildenden",
    names: (
      <div>
        {" "}
        <span style={{ fontWeight: "bold" }}>Ramona Capogreco</span> und{" "}
        <span style={{ fontWeight: "bold" }}>Vanessa Moscatello</span>
      </div>
    ),
    text: "Ramona Capogreco und Vanessa Moscatello sorgen für frischen Wind in unserer Praxis. Sie bereichern unser Team mit ihrer fröhlichen und offenen Art.",
    img: auszubildende,
  },

  {
    key: "Verwaltung",
    title: "Unser Verwaltungsteam",
    names: (
      <div>
        {" "}
        <span style={{ fontWeight: "bold" }}>Magdalena Stenger</span> und{" "}
        <span style={{ fontWeight: "bold" }}>Anna-Lena Mehling</span>
      </div>
    ),
    text: "Magdalena Stenger und Anna-Lena Mehling sorgen für einen reibungslosen Ablauf hinter den Kulissen. Bei Fragen rund um Ihre Anträge und Rechnungen stehen sie Ihnen gerne zur Verfügung.",
    img: verwaltung,
  },
];

class TeamMobile extends Component {
  render() {
    const { classes } = this.props;

    return (
      <div style={{ marginTop: 112 }}>
        <Helmet>
          <title>Team</title>
          <meta
            name="description"
            content="Unsere motivierten Mitarbeiter bilden ein starkes Team"
          />
        </Helmet>
        {/* <div className={classes.headlines} style={{ padding: "0px 16px" }}>
          Unser Team
        </div> */}
        <p
          style={{
            fontSize: 20,
            marginTop: 96,
            marginBottom: "40vh",
            textAlign: "center",
          }}
        >
          Wir überarbeiten unsere Webseite.
          <br /> Vielen Dank für Ihre Geduld.
        </p>
        {/* <div
          className={classes.flowText}
          style={{ padding: "10px 16px", fontSize: "1.1rem" }}
        >
          Unsere motivierten Mitarbeiter bilden ein starkes Team. Familiäre
          Atomsphäre und ein konstruktives Miteinander zeichnen uns aus. Das
          zeigt sich in langjähriger Praxiszugehörigkeit und Zuwachs von jungem,
          interessiertem Nachwuchs.
        </div>
        <div style={{ marginTop: 20 }}>
          {elements.map((element, key) => {
            return <GridItem element={element} key={key} />;
          })}
        </div> */}
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(TeamMobile);
