import React, { Component } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import "../../../App.css";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import { styles } from "../../../breakpoints";
import InputBase from "@material-ui/core/InputBase";
import * as emailjs from "emailjs-com";
import Checkbox from "@material-ui/core/Checkbox";
import clsx from "clsx";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import * as ROUTES from "../../../constants/routes";
import Link from "@material-ui/core/Link";
import CircularProgress from "@material-ui/core/CircularProgress";
import MapContainer from "../maps.js";
import {
  GoogleReCaptchaProvider,
  GoogleReCaptcha,
} from "react-google-recaptcha-v3";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Backdrop from "@material-ui/core/Backdrop";
import { Helmet } from "react-helmet";

const useStyles = makeStyles({
  root: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  icon: {
    borderRadius: 3,
    width: 16,
    height: 16,
    boxShadow:
      "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
    backgroundColor: "#f5f8fa",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
    "$root.Mui-focusVisible &": {
      outline: "2px auto rgba(19,124,189,.6)",
      outlineOffset: 2,
    },
    "input:hover ~ &": {
      backgroundColor: "#ebf1f5",
    },
    "input:disabled ~ &": {
      boxShadow: "none",
      background: "rgba(206,217,224,.5)",
    },
  },
  checkedIcon: {
    backgroundColor: "rgb(19, 41, 75)",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&:before": {
      display: "block",
      width: 16,
      height: 16,
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
      content: '""',
    },
    "input:hover ~ &": {
      backgroundColor: "rgba(19, 41, 75, 0.8)",
    },
  },
});

function StyledCheckbox(props) {
  const classes = useStyles();

  return (
    <Checkbox
      className={classes.root}
      disableRipple
      color="default"
      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
      icon={<span className={classes.icon} />}
      inputProps={{ "aria-label": "decorative checkbox" }}
      {...props}
    />
  );
}

const CssTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "#8e4232",
    },

    "& .MuiOutlinedInput-root": {
      "&:hover fieldset": {
        borderColor: "#8e4232",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#8e4232",
      },
    },
  },
})(TextField);

class Kontakt extends Component {
  constructor(props) {
    super(props);
    this.state = {
      vorname: "",
      nachname: "",
      email: "",
      nachricht: "",
      betreff: "Terminvereinbarung",
      labelWidth: 0,
      errorTextEmail: "",
      phone: "",
      emailError: false,
      consens: false,
      loading: false,
      sent: false,
      verified: false,
    };
    this.betreffRef = React.createRef();
  }

  componentDidMount() {
    this.setState({ labelWidth: this.betreffRef.current.offsetWidth });
  }

  handleChangeVorname = (event) => {
    this.setState({ vorname: event.target.value });
  };
  handleChangeNachname = (event) => {
    this.setState({ nachname: event.target.value });
  };
  handleChangeEmail = (event) => {
    this.setState({ email: event.target.value });
  };

  handleChangeNachricht = (event) => {
    this.setState({ nachricht: event.target.value });
  };
  handleChangeBetreff = (event) => {
    this.setState({ betreff: event.target.value });
  };

  handleChangePhone = (event) => {
    this.setState({ phone: event.target.value });
  };

  handleChangeConsens = (event) => {
    console.log(event.target.checked);
    this.setState({ consens: event.target.checked });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

    this.setState({ loading: true });

    let templateParams = {
      from_name: `${this.state.vorname} ${this.state.nachname}`,
      to_name: "",
      subject: this.state.betreff,
      message_html: this.state.nachricht,
      reply_to: this.state.email,
      phone: this.state.phone,
    };

    emailjs
      .send(
        "nachrichtenservice_link",
        "template_4dLX36jM",
        templateParams,
        "user_RyrlDZ9VlCgyGN2PJlvh3"
      )
      .then(() => {
        this.setState({
          vorname: "",
          nachname: "",
          email: "",
          phone: "",
          consens: false,
          nachricht: "",
          betreff: "Terminvereinbarung",
          loading: false,
          sent: true,
        });
      });
  };

  checkEmail() {
    let re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (re.test(this.state.email) || this.state.email.length === 0) {
      this.setState({
        emailError: false,
        errorTextEmail: "",
      });
    } else {
      this.setState({
        emailError: true,
        errorTextEmail: "Ungültige Email-Adresse",
      });
    }
  }

  handleClose = () => {
    this.setState({ sent: false });
  };

  render() {
    const {} = this.state;
    const { classes, theme } = this.props;

    return (
      <div>
        <Helmet>
          <title>Kontakt</title>
          <meta
            name="description"
            content="Teilen Sie uns jederzeit Ihr Anliegen mit. Wir melden uns umgehend zurück."
          />
        </Helmet>
        <Dialog
          open={this.state.sent}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Vielen Dank für Ihre Nachricht.
              <br />
              Wir melden uns umgehend zurück.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} style={{ color: "#13294b" }}>
              Schliessen
            </Button>
          </DialogActions>
        </Dialog>
        <Backdrop
          onClick={console.log("Can not be closed")}
          open={this.state.loading}
          style={{ zIndex: 1600 }}
        >
          <div style={{ textAlign: "center" }}>
            <CircularProgress color="inherit" style={{ color: "#ffffff" }} />
          </div>
        </Backdrop>

        <div
          className={classes.responsive}
          style={{ margin: "5vh auto 10vh auto" }}
        >
          <div className={classes.headlines} style={{ marginBottom: "4vh" }}>
            Kontakt
          </div>
          <div className={classes.flowText} style={{ marginBottom: "8vh" }}>
            Sie haben Fragen, möchten einen Termin vereinbaren oder haben ein
            anderes Anliegen? Dann schreiben Sie uns einfach eine Nachricht und
            wir melden uns umgehend bei Ihnen zurück. Alternativ erreichen Sie
            uns auch unter:
          </div>

          <div
            style={{
              textAlign: "center",
              marginBottom: "8vh",
              fontSize: "1.5rem",
            }}
          >
            <span style={{ fontWeight: "bold" }}> Tel.: </span>{" "}
            <a
              href="tel:060242220"
              style={{ color: "rgba(51, 51, 51, 1)", textDecoration: "none" }}
            >
              {" "}
              06024 / 2220{" "}
            </a>
            <br />
            <span style={{ fontWeight: "bold" }}> E-Mail: </span>{" "}
            <a
              href="mailto:info@zahnarztpraxis-link.de"
              style={{ color: "rgba(51, 51, 51, 1)", textDecoration: "none" }}
            >
              info@zahnarztpraxis-link.de{" "}
            </a>
          </div>
          <div
            style={{ width: "100%", marginLeft: "auto", marginRight: "auto" }}
          >
            <form onSubmit={this.handleSubmit}>
              <div style={{ marginBottom: "5vh" }}>
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel
                    ref={this.betreffRef}
                    id="demo-simple-select-outlined-label"
                    className={classes.label}
                  >
                    Betreff
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={this.state.betreff}
                    onChange={this.handleChangeBetreff}
                    labelWidth={this.state.labelWidth}
                  >
                    <MenuItem value="Terminvereinbarung">
                      <em>Terminvereinbarung</em>
                    </MenuItem>
                    <MenuItem value="Sonstiges">Sonstiges</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div
                style={{
                  display: "flex",
                  marginBottom: "5vh",
                  justifyContent: "space-between",
                }}
              >
                <CssTextField
                  style={{ width: "45%" }}
                  required
                  label="Vorname"
                  onChange={this.handleChangeVorname}
                  variant="outlined"
                  value={this.state.vorname}
                />
                <CssTextField
                  style={{ width: "45%" }}
                  required
                  label="Nachname"
                  onChange={this.handleChangeNachname}
                  variant="outlined"
                  value={this.state.nachname}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  marginBottom: "5vh",
                  justifyContent: "space-between",
                }}
              >
                <CssTextField
                  style={{ width: "45%" }}
                  required
                  label="E-Mail Adresse"
                  onChange={this.handleChangeEmail}
                  variant="outlined"
                  onBlur={() => this.checkEmail()}
                  helperText={this.state.errorTextEmail}
                  error={this.state.emailError}
                  value={this.state.email}
                />
                <CssTextField
                  style={{ width: "45%" }}
                  label="Telefon"
                  onChange={this.handleChangePhone}
                  variant="outlined"
                  value={this.state.phone}
                />
              </div>
              <CssTextField
                required
                fullWidth
                label="Nachricht"
                onChange={this.handleChangeNachricht}
                variant="outlined"
                multiline
                rows="10"
                style={{ display: "block", marginBottom: "5vh" }}
                value={this.state.nachricht}
              />

              <FormControlLabel
                control={
                  <StyledCheckbox
                    checked={this.state.consens}
                    onChange={this.handleChangeConsens}
                    value="default"
                  />
                }
                label=<div>
                  Ich stimme der Verarbeitung meiner Daten gemäß der
                  <span>
                    {" "}
                    <Link
                      component="button"
                      style={{
                        color: "#333",
                        fontWeight: "bold",
                        textDecoration: "underline",
                        fontSize: "1rem",
                      }}
                      onClick={() =>
                        this.props.history.push(ROUTES.DATENSCHUTZ)
                      }
                    >
                      Datenschutzerklärung
                    </Link>{" "}
                  </span>
                  zu. Ich kann meine Einwilligung jederzeit per E-Mail an
                  datenschutz@zahnarztklinik-link.de widerrufen.
                </div>
              />
              <GoogleReCaptchaProvider reCaptchaKey="6LfJeegUAAAAADslDB7Qk3uIeUMafO4MY7IeB35k">
                <GoogleReCaptcha
                  onVerify={(token) => this.setState({ verified: true })}
                />
              </GoogleReCaptchaProvider>
              <Button
                disabled={!this.state.consens || this.state.emailError}
                className={classes.button}
                style={{ marginBottom: "8vh" }}
                label="Submit"
                type="submit"
                variant="contained"
              >
                Nachricht senden
              </Button>
            </form>
          </div>

          <div>
            <div className={classes.headlines} style={{ marginBottom: "4vh" }}>
              Anfahrt
            </div>
            <div className={classes.flowText} style={{ marginBottom: "4vh" }}>
              Ullastr. 3
              <br />
              63829 Krombach
              <br />
              <br />
              Wir freuen uns auf Ihren Besuch!
            </div>
            <MapContainer />
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(Kontakt);
