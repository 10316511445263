import React, { Component } from "react";
import "../../../App.css";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { withStyles} from "@material-ui/core/styles";

import { styles } from "../../../breakpoints";
import recall from "../../../assets/recall.jpg";
import techniker from "../../../assets/techniker_leistung.jpg";
import kinder from "../../../assets/kinder_leistung.jpg";
import beratung from "../../../assets/beratung_leistung.png";
import prophylaxe from "../../../assets/prophylaxe_leistung.jpg";
import cerec from "../../../assets/cerec.jpg";
import schnarcherschiene from "../../../assets/schnarcherschiene.jpg";
import teen_clean from "../../../assets/teen_clean.jpg";
import zahnspangenreinigung from "../../../assets/zahnspangenreinigung.jpg";
import cerec_2 from "../../../assets/cerec_2.jpg";

import { Helmet } from "react-helmet";

import Divider from "@material-ui/core/Divider";

class Infos extends Component {


  render() {
    const { classes } = this.props;

    return (
      <div style={{ marginTop: 112 }}>
        <Helmet>
          <title>Infos</title>
          <meta
            name="description"
            content="Informationen zu unseren Leistungen."
          />
        </Helmet>
        <p
          style={{
            fontSize: 20,
            marginTop: 96,
            marginBottom: "40vh",
            textAlign: "center",
          }}
        >
          Wir überarbeiten unsere Webseite.
          <br /> Vielen Dank für Ihre Geduld.
        </p>
{/* 
        <div style={{ textAlign: "center" }}>
          <div>
            <div style={{ marginTop: "2vh" }}>
              <div style={{ width: "100%" }}>
                <p
                  className={classes.headlines}
                  style={{ paddingLeft: 16, paddingRight: 16 }}
                >
                  Infos
                </p>
                <br />
                <p
                  className={classes.flowText}
                  style={{ paddingLeft: 16, paddingRight: 16 }}
                >
                  Unsere familiär geführte Praxis möchte Ihren Zahnarztbesuch so
                  angenehm wie möglich gestalten. Wohlfühlatmosphäre für alle,
                  von 0 bis 110 Jahren liegt uns am Herzen. Ihre Anliegen nehmen
                  wir ernst und suchen mit Ihnen zusammen nach Lösungen, die auf
                  Ihre Bedürfnisse abgestimmt sind.
                  <br />
                  <br />
                  Einfühlsam widmen wir uns der Gesundheit des ersten Zahnes
                  Ihres Kindes, wie der Gesundheit aller Zähne, über die
                  Prophylaxe in jeder Generation, bis hin zum eventuell
                  benötigten Zahnersatz, immer unter Berücksichtigung einer
                  ganzheitlichen Betrachtung.
                </p>
                <br />
                <div style={{ position: "relative" }}>
                  <img src={recall} style={{ width: "100%" }} />
                  <div
                    style={{
                      position: "absolute",
                      top: 10,
                      left: 10,
                      fontSize: "1.8rem",
                      color: "#13294b",
                      fontWeight: "bold",
                      backgroundColor: "rgba(255, 255, 255, 0.7)",
                      padding: "0px 10px 10px 10px",
                    }}
                  >
                    {" "}
                    Recall
                    <Divider
                      style={{
                        height: 4,
                        width: "80%",
                        backgroundColor: "rgb(142, 66, 50)",
                      }}
                    />{" "}
                  </div>
                </div>
                <p
                  className={classes.flowText}
                  style={{ paddingLeft: 16, paddingRight: 16 }}
                >
                  <br />
                  Ihre Zahngesundheit liegt uns am Herzen. Im Rahmen unseres
                  Erinnerungsservices (Recall) können wir Sie dabei
                  unterstützen, regelmäßige Kontrolltermine zu vereinbaren.
                  <br />
                  <br />
                  Wir kontaktieren Sie, ganz nach Ihren Wünschen:
                  <br />
                  <br />
                  <span style={{ fontWeight: "bold" }}>
                    Telefonisch, per E-Mail
                  </span>{" "}
                  oder <span style={{ fontWeight: "bold" }}>Postkarte</span>.
                  <br />
                  <br />
                  Bitten denken Sie daran, uns Adress- und Datenänderungen
                  mitzuteilen.
                </p>
                <br />
                <br />
                <div style={{ position: "relative" }}>
                  <img src={prophylaxe} style={{ width: "100%" }} />
                  <div
                    style={{
                      position: "absolute",
                      top: 10,
                      left: 10,
                      fontSize: "1.8rem",
                      color: "#13294b",
                      fontWeight: "bold",
                      backgroundColor: "rgba(255, 255, 255, 0.7)",
                      padding: "0px 10px 10px 10px",
                    }}
                  >
                    {" "}
                    Prophylaxe
                    <Divider
                      style={{
                        height: 4,
                        width: "80%",
                        backgroundColor: "rgb(142, 66, 50)",
                      }}
                    />{" "}
                  </div>
                </div>
                <p
                  className={classes.flowText}
                  style={{ paddingLeft: 16, paddingRight: 16 }}
                >
                  <br />
                  Unser fortgebildetes Prophylaxeteam sorgt für eine
                  professionelle Reinigung Ihrer Zähne und somit für eine
                  langfristige Zahngesundheit.
                  <br />
                  <br />
                  Dabei nutzen wir, gestützt auf unsere langjährige Erfahrung,
                  die Prinzipien der{" "}
                  <span style={{ fontWeight: "bold" }}>
                    Guided Biofilm Therapy
                  </span>
                  . Eine bestmögliche Reinigung bei höchstmöglicher Schonung ist
                  unser Anspruch.
                  <br />
                  <br />
                  Unsere Dentalhygienikerin{" "}
                  <span style={{ fontWeight: "bold" }}>
                    Anna Amberg (BA)
                  </span>{" "}
                  begleitet Sie bei Parodontitis (früher Parodontose genannt)
                  und Bleaching.
                </p>
                <br />
                
                <Accordion
                  classes={{ root: classes.root }}
                  style={{
                    height: "fit-content",
                    gridColumnEnd: "span 1",
                    position: "relative",
                    backgroundColor: "rgb(19, 41, 75)",
                    boxShadow: "none",
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon style={{ color: "#ffffff" }} />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography
                      style={{
                        fontWeight: "bold",
                        fontSize: "1.4rem",
                        color: "#ffffff",
                      }}
                    >
                      Paradontitis
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails style={{ display: "block" }}>
                    <img src={cerec} style={{ width: "100%" }} />

                    <Typography style={{ textAlign: "justify", wordBreak: 'break-word', color: "#ffffff" }}>
                      <br />
                      Bis zu 90% der Bevölkerung können an einer Entzündung des
                      Zahnhalteapparates, einer Parodontitis, erkranken. Eine
                      nicht behandelte Parodontitis endet früher oder später mit
                      dem Zahnverlust durch Knochenabbau. Leider reagiert man
                      als Betroffener auf Zahnfleischbluten, Mundgeruch und
                      Zahnlockerungen oft zu spät.
                      <br />
                      <br />
                      Bei der Therapie Ihrer Parodontitis sollte in einem ersten
                      Schritt unter professioneller Anleitung eine Ihrer
                      Situation angepasste, gute Mundhygiene etabliert werden.
                      Dabei erfolgt eine professionelle mechanische
                      Plaquereduktion. Im zweiten Schritt folgt die Reinigung
                      der Zahnoberflächen auch unterhalb des Zahnfleischrands.
                      <br />
                      <br />
                      Für einen langfristigen Erfolg muss Ihre gute Mundhygiene
                      aufrechterhalten werden und eine regelmäßige
                      unterstützende Parodontaltherapie (UPT) folgen.
                      <br />
                      <br />
                      Dies hat auch einen positiven Effekt auf die allgemeine
                      Gesundheit. Gerade bei Bluthochdruck und Diabetes konnten
                      Verbesserungen durch eine Parodontitistherapie
                      nachgewiesen werden.
                    </Typography>

                  </AccordionDetails>
                </Accordion>
                <Accordion
                  classes={{ root: classes.root }}
                  style={{
                    height: "fit-content",
                    gridColumnEnd: "span 1",
                    position: "relative",
                    backgroundColor: "rgb(19, 41, 75)",
                    boxShadow: "none",
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon style={{ color: "#ffffff" }} />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography
                      style={{
                        fontWeight: "bold",
                        fontSize: "1.4rem",
                        color: "#ffffff",
                      }}
                    >
                      TeenClean©
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails style={{ display: "block" }}>
                    <img src={teen_clean} style={{ width: "100%" }} />

                    <Typography style={{ textAlign: "justify", wordBreak: 'break-word', color: "#ffffff" }}>
                      <br />
                      Bist du zwischen 13 und 17 Jahre alt und möchtest strahlende Zähne? Dann ist unsere TeenClean© genau das richtige für dich. Wir bieten eine professionelle Zahnreinigung speziell für Teenager mit Tipps für langanhaltend strahlende Zähne und einen frischen Atem.
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  classes={{ root: classes.root }}
                  style={{
                    height: "fit-content",
                    gridColumnEnd: "span 1",
                    position: "relative",
                    backgroundColor: "rgb(19, 41, 75)",
                    boxShadow: "none",
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon style={{ color: "#ffffff" }} />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography
                      style={{
                        fontWeight: "bold",
                        fontSize: "1.4rem",
                        color: "#ffffff",
                        textAlign: 'left'
                      }}
                    >
                      Professionelle Zahnspangenreinigung
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails style={{ display: "block" }}>
                    <img src={zahnspangenreinigung} style={{ width: "100%" }} />

                    <Typography style={{ textAlign: "justify", wordBreak: 'break-word', color: "#ffffff" }}>
                      <br />
                      Festsitzende Zahnspangen machen schöne Zähne. Aber sie sind auch besonders anspruchsvoll in der Pflege. Damit die Zähne gesund bleiben, empfehlen wir eine professionelle Zahnreinigung speziell für Zahnspangenträger.
                      <br/>
                      <br/>
                      Hierbei wird mit dem Pulverstrahlgerät jede leicht zu übersehende Stelle gereinigt. Die ergänzenden Tipps zur Reinigung lohnen sich besonders am Anfang einer kieferorthopädischen Therapie.
                    </Typography>
                  </AccordionDetails>
                </Accordion>

                <div style={{ position: "relative", marginTop: 20 }}>
                  <img src={kinder} style={{ width: "100%" }} />
                  <div
                    style={{
                      position: "absolute",
                      top: 10,
                      right: 10,
                      fontSize: "1.8rem",
                      color: "#13294b",
                      fontWeight: "bold",
                      backgroundColor: "rgba(255, 255, 255, 0.7)",
                      padding: "0px 10px 10px 10px",
                    }}
                  >
                    {" "}
                    Kinderzahnheilkunde
                    <Divider
                      style={{
                        height: 4,
                        width: "80%",
                        backgroundColor: "rgb(142, 66, 50)",
                        marginLeft: "auto",
                      }}
                    />{" "}
                  </div>
                </div>
                <p
                  className={classes.flowText}
                  style={{ paddingLeft: 16, paddingRight: 16 }}
                >
                  <br />
                  Gerade bei den jüngsten Patienten braucht es besonders viel{" "}
                  <span style={{ fontWeight: "bold" }}>Umsicht</span> und{" "}
                  <span style={{ fontWeight: "bold" }}>
                    Einfühlungsvermögen.
                  </span>
                  <br />
                  <br />
                  Spielerisch und mit viel Freude legen wir den Grundstein für
                  ein lebenslanges, gesundes Beißen.
                  <br />
                  <br />
                  Schon ab dem ersten Milchzahn sind die Kleinen bei uns ganz
                  groß.
                </p>
                <br />
               
                <Accordion
                  classes={{ root: classes.root }}
                  style={{
                    height: "fit-content",
                    gridColumnEnd: "span 1",
                    position: "relative",
                    backgroundColor: "rgb(19, 41, 75)",
                    boxShadow: "none",
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon style={{ color: "#ffffff" }} />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography
                      style={{
                        fontWeight: "bold",
                        fontSize: "1.4rem",
                        color: "#ffffff",
                        textAlign: 'left'
                      }}
                    >
                      Putzschule
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails style={{ display: "block" }}>
                    

                    <Typography style={{ textAlign: "justify", wordBreak: 'break-word', color: "#ffffff" }}>
                    
                      Von Anfang an gesunde Zähne. In der Putzschule üben wir mit unseren kleinen Patienten (Kinder zwischen 6 und 12 Jahren) das optimale Zähneputzen. Hierfür sollte die eigene Zahnbürste mitgebracht werden.                      <br/>
                      
                    </Typography>
                  </AccordionDetails>
                </Accordion>
               
                  <div
                    style={{
                      fontSize: "1.8rem",
                      color: "rgb(19, 41, 75)",
                      fontWeight: "bold",
                      textAlign: "left",
                      paddingLeft: "16px",
                      marginTop: "20px"
                    }}
                  >
                    {" "}
                    Zahntechnik
                    <Divider
                      style={{
                        height: 4,
                        width: "40%",
                        backgroundColor: "rgb(142, 66, 50)",
                      }}
                    />{" "}
                  </div>
               
                <p
                  className={classes.flowText}
                  style={{ paddingLeft: 16, paddingRight: 16 }}
                >
                  <br />
                  Wir vereinen in unserer Zahntechnik erfahrene <span style={{ fontWeight: "bold" }}>Kompetenz</span> mit moderner <span style={{ fontWeight: "bold" }}>Innovation</span>.

                  <br />
                  <br />
                  So werden neben den bewährten Klassikern auch
                  computergestützte Verfahren, wie CAD/CAM, zur&nbsp;
                  <span style={{ fontWeight: "bold" }}>
                    Perfektionierung
                  </span>{" "}
                  und <span style={{ fontWeight: "bold" }}>Optimierung</span>{" "}
                  eingesetzt.
                </p>
                <br />
                <Accordion
                  classes={{ root: classes.root }}
                  style={{
                    height: "fit-content",
                    gridColumnEnd: "span 1",
                    position: "relative",
                    backgroundColor: "rgb(19, 41, 75)",
                    boxShadow: "none",
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon style={{ color: "#ffffff" }} />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography
                      style={{
                        fontWeight: "bold",
                        fontSize: "1.4rem",
                        color: "#ffffff",
                      }}
                    >
                      Cerec®
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails style={{ display: "block" }}>
                    <img src={cerec} style={{ width: "100%" }} />

                    <Typography style={{ textAlign: "justify", wordBreak: 'break-word', color: "#ffffff" }}>
                      <br />
                      Das Cerec® System erlaubt es Kronen und Brücken maschinell
                      gefräst innerhalb{" "}
                      <span style={{ fontWeight: "bold" }}>
                        weniger Stunden
                      </span>{" "}
                      herzustellen. Somit könnte an einem einzigen Tag ein Zahn
                      beschliffen und die definitive Restauration eingesetzt
                      werden.
                      <br />
                      <br />
                      Mittels computer-aided designs (CAD) erfolgt die
                      Gestaltung der Versorgung am Computer und anschließend die
                      computergesteuerte Fräsung (computer-aided manufacturing,
                      CAM). Auch die sonst notwendigen, manchmal unangenehmen,{" "}
                      <span style={{ fontWeight: "bold" }}>Abdrücke</span>{" "}
                      können durch intraorale Scans oft vermieden werden.
                    </Typography>
                    <br />
                    <img src={cerec_2} style={{ width: "100%" }} />

                  </AccordionDetails>
                </Accordion>
                <Accordion
                  classes={{ root: classes.root }}
                  style={{
                    height: "fit-content",
                    gridColumnEnd: "span 1",
                    position: "relative",
                    backgroundColor: "rgb(19, 41, 75)",
                    boxShadow: "none",
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon style={{ color: "#ffffff" }} />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                  >
                    <Typography
                      style={{
                        fontWeight: "bold",
                        fontSize: "1.4rem",
                        color: "#ffffff",
                      }}
                    >
                      Schnarcherschiene
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails style={{ display: "block" }}>
                    <img src={schnarcherschiene} style={{ width: "100%" }} />
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.tap-schiene.de/start/"
                      style={{ color: "#ffffff" }}
                    >
                      TAP® Schiene
                    </a>

                    <Typography style={{ textAlign: "justify", wordBreak: 'break-word', color: "#ffffff" }}>
                      <br />
                      Rund die Hälfte der Bevölkerung neigt zum Schnarchen.
                      Hierdurch kann es zu einer geringeren Sauerstoffaufnahme
                      kommen. Dies stört, neben den Partnern, auch das eigene
                      Wohlbefinden und reduziert die{" "}
                      <span style={{ fontWeight: "bold" }}>Schlafqualität</span>
                      . Sekundenschlaf und schlechte Konzentration sind die
                      Folge. Bluthochdruck, Schlaganfall- und Diabetesrisiko
                      sind erhöht. Ähnliche Probleme bringt das
                      Schlafapnoe-Syndrom mit sich.
                      <br />
                      <br />
                      Bei schweren Beeinträchtigungen müssen operative Eingriffe
                      oder eine Überdruckbeatmung mittels Maske erwogen werden.
                      Bei leichteren Formen können wir Ihnen mittels einer
                      Unterkiefer-Protrusionsschiene (so genannte
                      Schnarcherschiene) helfen. Diese hält den Unterkiefer
                      vorne, wodurch die Zunge nicht die Atemwege blockieren
                      kann.
                    </Typography>
                  </AccordionDetails>
                </Accordion>

                <div
                  style={{
                    fontSize: "1.8rem",
                    color: "#13294b",
                    fontWeight: "bold",
                    textAlign: "left",
                    paddingLeft: 16,
                    marginTop: 20,
                  }}
                >
                  {" "}
                  Bleaching
                  <Divider
                    style={{
                      height: 4,
                      width: "20%",
                      backgroundColor: "rgb(142, 66, 50)",
                    }}
                  />{" "}
                </div>
              </div>
              <div style={{ position: "relative" }}>
                <p
                  className={classes.flowText}
                  style={{ paddingLeft: 16, paddingRight: 16 }}
                >
                  <br />
                  Wer viel Wert auf gesunde Zähne legt, zeigt das mit einem
                  strahlenden Lächeln. Lassen Sie sich gerne bei unserer
                  Dentalhygienikerin Anna Amberg individuell beraten, welche
                  Möglichkeiten es gibt, ihre Zähne möglichst schonend
                  aufzuhellen und dabei ein natürliches Ergebnis zu erzielen.
                </p>

                <div
                  style={{
                    fontSize: "1.8rem",
                    color: "#13294b",
                    fontWeight: "bold",
                    textAlign: "left",
                    paddingLeft: 16,
                    marginTop: 20,
                  }}
                >
                  {" "}
                  Lachgas
                  <Divider
                    style={{
                      height: 4,
                      width: "20%",
                      backgroundColor: "rgb(142, 66, 50)",
                    }}
                  />{" "}
                </div>
              </div>
              <div style={{ position: "relative" }}>
                <p
                  className={classes.flowText}
                  style={{ paddingLeft: 16, paddingRight: 16 }}
                >
                  <br />
                  Gerade bei Angstpatienten oder in der Kinderzahnheilkunde
                  erfreut sich die dentale Lachgasanwendung zunehmender
                  Beliebtheit.
                  <br />
                  Lachgas inhaliert, wirkt schnell und zuverlässig. Es reduziert
                  die Angst beim zahnärztlichen Eingriff. Außerdem ist der
                  Würgereiz stark reduziert und eine leichte Schmerzausschaltung
                  findet statt.
                </p>

                <div
                  style={{
                    fontSize: "1.8rem",
                    color: "#13294b",
                    fontWeight: "bold",
                    textAlign: "left",
                    paddingLeft: 16,
                    marginTop: 20,
                  }}
                >
                  {" "}
                  Beratung
                  <Divider
                    style={{
                      height: 4,
                      width: "20%",
                      backgroundColor: "rgb(142, 66, 50)",
                    }}
                  />{" "}
                </div>
              </div>
              <div style={{ position: "relative" }}>
                <p
                  className={classes.flowText}
                  style={{ paddingLeft: 16, paddingRight: 16 }}
                >
                  <br />
                  Wir freuen uns über Ihren Anruf und beraten Sie gerne
                  persönlich.
                </p>

                <img
                  src={beratung}
                  style={{
                    width: "90%",
                    boxShadow: "0 25px 50px -12px rgba(0, 0, 0, 0.5)",
                    marginTop: 20,
                    marginBottom: 20,
                    maxWidth: 500,
                  }}
                />
              </div>
            </div>
          </div>
        </div> */}
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(Infos);
