import React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

import * as ROUTES from "../constants/routes";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";

const useStyles = makeStyles((theme) => ({
  responsive: {
    padding: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      width: "96%",
    },
    [theme.breakpoints.up("md")]: {
      width: "90%",
    },
    [theme.breakpoints.up("lg")]: {
      width: "80%",
    },
    [theme.breakpoints.up("xl")]: {
      width: "60%",
    },
  },
  footer: {
    marginLeft: "auto",
    marginRight: "auto",
    color: "#000",
    padding: "30px 0px",
    display: "flex",
    justifyContent: "space-between",
  },
  dividerColor: {
    backgroundColor: "white",
    marginTop: 16,
  },
}));

export default function Footer(props) {
  const classes = useStyles();

  return (
    <div>
      <div
        style={{
          backgroundColor: "#8e4232",
          marginTop: "auto",
          fontSize: "1.2rem",
          padding: "7vh 10vw",
        }}
      >
        <div className={classes.footers}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <div style={{ color: "#ffffff", width: "25%" }}>
              <span style={{ fontWeight: "bold", fontSize: "1.5em" }}>
                {" "}
                Anschrift{" "}
              </span>
              <Divider className={classes.dividerColor} />
              <br />
              <span style={{ fontWeight: "bold" }}> Zahnarztpraxis Link </span>
              <br />
              Ullastr. 3
              <br />
              63829 Krombach
              <br />
              <br />
              <span style={{ fontWeight: "bold" }}> Tel.: </span>{" "}
              <a
                href="tel:060242220"
                style={{ color: "#ffffff", textDecoration: "none" }}
              >
                {" "}
                06024 / 2220{" "}
              </a>
              <br />
              <span style={{ fontWeight: "bold" }}>E-Mail: </span>{" "}
              <a
                href="mailto:info@zahnarztpraxis-link.de"
                style={{ color: "#ffffff", textDecoration: "none" }}
              >
                {" "}
                info@zahnarztpraxis-link.de{" "}
              </a>
            </div>

            <div style={{ color: "#ffffff", width: "25%" }}>
              <span style={{ fontWeight: "bold", fontSize: "1.5em" }}>
                {" "}
                Öffnungszeiten{" "}
              </span>
              <Divider className={classes.dividerColor} />
              <br />
              Montag & Dienstag
              <br />
              8.00 Uhr - 12.00 Uhr
              <br />
              14.00 Uhr - 18.00 Uhr
              <br /> <br />
              Mittwoch
              <br />
              8.00 Uhr - 12.00 Uhr
              <br />
              <span style={{ fontWeight: "bold" }}>
                {" "}
                Nachmittags Zungenbandsprechstunde nach Vereinbarung
              </span>
              <br /> <br />
              Donnerstag & Freitag
              <br />
              8.00 Uhr - 12.00 Uhr
              <br />
              14.00 Uhr - 18.00 Uhr
              <br />
            </div>

            <div style={{ color: "#ffffff", width: "25%" }}>
              <span style={{ fontWeight: "bold", fontSize: "1.5em" }}>
                {" "}
                Notdienst{" "}
              </span>
              <Divider className={classes.dividerColor} />
              <br />
              An Wochenenden, Sonn- und Feiertagen wenden Sie sich bitte an den
              zahnärztlichen Notdienst unter
              <span style={{ fontWeight: "bold" }}>
                {" "}
                Tel.:{" "}
                <a
                  href="tel:0602180700"
                  style={{ color: "#ffffff", textDecoration: "none" }}
                >
                  06021 / 80 700
                </a>{" "}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div style={{ width: "100%", backgroundColor: "#612d22" }}>
        <Typography
          style={{
            marginTop: "auto",
            marginBottom: "auto",
            alignItems: "center",
            height: 40,
            color: "#ffffff",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Link
            component="button"
            style={{ fontSize: "1rem", marginRight: 60 }}
            onClick={() => props.history.push(ROUTES.DATENSCHUTZ)}
            color="inherit"
          >
            {" "}
            Datenschutz{" "}
          </Link>

          <Link
            component="button"
            style={{ fontSize: "1rem", marginRight: 60 }}
            onClick={() => props.history.push(ROUTES.IMPRESSUM)}
            color="inherit"
          >
            {" "}
            Impressum{" "}
          </Link>
          <Link
            component="button"
            style={{ fontSize: "1rem", marginRight: 60 }}
            onClick={() => props.history.push(ROUTES.BEWERBUNGEN)}
            color="inherit"
          >
            {" "}
            Bewerbungen{" "}
          </Link>
          <Link
            component="button"
            style={{ fontSize: "1rem", marginRight: 60 }}
            onClick={() => props.history.push(ROUTES.KONTAKT)}
            color="inherit"
          >
            {" "}
            Kontakt{" "}
          </Link>
        </Typography>
      </div>
    </div>
  );
}
