import React from "react";
import ButtonBase from "@material-ui/core/ButtonBase";
import Slide from "@material-ui/core/Slide";
import logo from "../assets/logo.svg";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import * as ROUTES from "../constants/routes";
import Fade from "@material-ui/core/Fade";

import { history } from "./history.js";

const bavarianHolidays2025to2028 = [
  // 2025
  "2025-01-01", // Neujahr
  "2025-01-06", // Heilige Drei Könige
  "2025-04-18", // Karfreitag
  "2025-04-21", // Ostermontag
  "2025-05-01", // Tag der Arbeit
  "2025-05-29", // Christi Himmelfahrt
  "2025-06-09", // Pfingstmontag
  "2025-06-19", // Fronleichnam
  "2025-08-15", // Mariä Himmelfahrt
  "2025-10-03", // Tag der Deutschen Einheit
  "2025-11-01", // Allerheiligen
  "2025-12-25", // 1. Weihnachtstag
  "2025-12-26", // 2. Weihnachtstag

  // 2026
  "2026-01-01",
  "2026-01-06",
  "2026-04-03", // Karfreitag
  "2026-04-06", // Ostermontag
  "2026-05-01",
  "2026-05-14", // Christi Himmelfahrt
  "2026-05-25", // Pfingstmontag
  "2026-06-04", // Fronleichnam
  "2026-08-15",
  "2026-10-03",
  "2026-11-01",
  "2026-12-25",
  "2026-12-26",

  // 2027
  "2027-01-01",
  "2027-01-06",
  "2027-03-26", // Karfreitag
  "2027-03-29", // Ostermontag
  "2027-05-01",
  "2027-05-06", // Christi Himmelfahrt
  "2027-05-17", // Pfingstmontag
  "2027-05-27", // Fronleichnam
  "2027-08-15",
  "2027-10-03",
  "2027-11-01",
  "2027-12-25",
  "2027-12-26",

  // 2028
  "2028-01-01",
  "2028-01-06",
  "2028-04-14", // Karfreitag
  "2028-04-17", // Ostermontag
  "2028-05-01",
  "2028-05-25", // Christi Himmelfahrt
  "2028-06-05", // Pfingstmontag
  "2028-06-15", // Fronleichnam
  "2028-08-15",
  "2028-10-03",
  "2028-11-01",
  "2028-12-25",
  "2028-12-26",
];

const useStyles = makeStyles((theme) =>
  createStyles({
    logo: {
      "&:hover": {
        opacity: 0.8,
      },
    },
    scrollLogo: {
      position: "absolute",
      [theme.breakpoints.down("sm")]: {
        visibility: "hidden",
      },
      [theme.breakpoints.up("md")]: {
        left: 0,
      },
      [theme.breakpoints.up("lg")]: {
        left: 0,
      },
      [theme.breakpoints.up("xl")]: {
        left: "4.5%",
      },
    },
  })
);

export default function ScrollLogo() {
  const [shouldShowActions, setShouldShowActions] = React.useState(false);
  const [shouldShowOpening, setShouldShowOpening] = React.useState(false);
  const [shouldShowVisible, setShouldShowVisible] = React.useState(false);

  const classes = useStyles();

  React.useEffect(() => {
    function handleScroll() {
      const yPos = window.scrollY;
      const showImage = yPos > 180;
      const showOpening = yPos > 700;
      const showVisible = yPos > 710;

      setShouldShowActions(showImage);
      setShouldShowOpening(showOpening);
      setShouldShowVisible(showVisible);
    }

    window.addEventListener("scroll", handleScroll, false);

    return () => {
      window.removeEventListener("scroll", handleScroll, false);
    };
  }, [shouldShowActions]);

  const checkIfOpen = () => {
    // Öffnungszeiten
    const startTimeMorning = "08:00:00";
    const endTimeMorning = "12:00:00";
    const startTimeEvening = "14:00:00";
    const endTimeEvening = "18:00:00";

    // Aktuelles Datum und Uhrzeit
    const currentDate = new Date();

    // String im Format YYYY-MM-DD für den Feiertagsvergleich
    const currentDateString = currentDate.toISOString().split("T")[0];

    // (1) An Feiertagen geschlossen
    if (bavarianHolidays2025to2028.includes(currentDateString)) {
      return false;
    }

    // (2) Am Wochenende (Sa = 6, So = 0) geschlossen
    if (currentDate.getDay() === 6 || currentDate.getDay() === 0) {
      return false;
    }

    // (3) Mittwoch Nachmittag (14:00 bis 18:00) geschlossen
    if (
      currentDate.getDay() === 3 &&
      currentDate.getHours() >= 14 &&
      currentDate.getHours() < 18
    ) {
      return false;
    }

    // Hilfsfunktion, um aus "HH:MM:SS" eine entsprechende Date-Objekt-Zeit zu setzen
    const parseTime = (timeStr) => {
      const [h, m, s] = timeStr.split(":").map(Number);
      return { h, m, s };
    };

    const morningStart = parseTime(startTimeMorning);
    const morningEnd = parseTime(endTimeMorning);
    const eveningStart = parseTime(startTimeEvening);
    const eveningEnd = parseTime(endTimeEvening);

    // Tägliche Zeitfenster definieren
    const startDateMorning = new Date(currentDate);
    startDateMorning.setHours(
      morningStart.h,
      morningStart.m,
      morningStart.s,
      0
    );

    const endDateMorning = new Date(currentDate);
    endDateMorning.setHours(morningEnd.h, morningEnd.m, morningEnd.s, 0);

    const startDateEvening = new Date(currentDate);
    startDateEvening.setHours(
      eveningStart.h,
      eveningStart.m,
      eveningStart.s,
      0
    );

    const endDateEvening = new Date(currentDate);
    endDateEvening.setHours(eveningEnd.h, eveningEnd.m, eveningEnd.s, 0);

    // (4) Prüfen, ob aktuelle Zeit in eines der beiden Zeitfenster fällt
    const isWithinMorning =
      currentDate >= startDateMorning && currentDate < endDateMorning;
    const isWithinEvening =
      currentDate >= startDateEvening && currentDate < endDateEvening;

    return isWithinMorning || isWithinEvening;
  };
  if (shouldShowActions) {
    return (
      <Slide in={shouldShowActions}>
        <div className={classes.scrollLogo}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              boxShadow:
                "0 4px 2px -2px rgba(0, 0, 0, 0.3), 0 4px 4px 0px rgba(0, 0, 0, 0.1)",
            }}
          >
            <ButtonBase onClick={() => history.push(ROUTES.HOME)}>
              <div
                style={{
                  position: "relative",
                  zIndex: 200,
                  backgroundColor: "#ffffff",
                  width: 200,
                  padding: 10,
                }}
              >
                <img
                  className={classes.logo}
                  alt="logo"
                  src={logo}
                  style={{ height: 120, margin: "auto" }}
                />
              </div>
            </ButtonBase>

            {shouldShowVisible && (
              <Fade in={shouldShowOpening} timeout={1000}>
                <div
                  style={{
                    display: "flex",
                    width: "200px",
                    backgroundColor: "#ffffff",
                  }}
                >
                  <div
                    style={{
                      margin: "auto auto 0px auto",
                      padding: "7px 14px 14px 14px",
                    }}
                  >
                    <span
                      style={{
                        color: "#13294b",
                        fontWeight: "bold",
                        fontSize: "1.1rem",
                        textAlign: "left",
                      }}
                    >
                      Öffnungszeiten{" "}
                    </span>
                    <div
                      style={{
                        textAlign: "left",
                        fontSize: "1rem",
                        marginTop: 10,
                      }}
                    >
                      {" "}
                      Montag & Dienstag
                      <br />
                      8.00 Uhr - 12.00 Uhr
                      <br />
                      14.00 Uhr - 18.00 Uhr
                      <br /> <br />
                      Mittwoch
                      <br />
                      8.00 Uhr - 12.00 Uhr
                      <br />
                      <span
                        style={{
                          fontWeight: "bold",
                          fontSize: "0.8rem",
                          lineHeight: "1rem",
                          display: "flex",
                          flexFlow: "row nowrap",
                          marginTop: 6,
                        }}
                      >
                        {" "}
                        Nachmittags Zungenbandsprechstunde nach Vereinbarung
                      </span>
                      <br />
                      Donnerstag & Freitag
                      <br />
                      8.00 Uhr - 12.00 Uhr
                      <br />
                      14.00 Uhr - 18.00 Uhr
                      <br />
                      <span
                        style={{
                          textAlign: "center",
                          display: "flex",
                          marginTop: 12,
                        }}
                      ></span>
                      {checkIfOpen() ? (
                        <div
                          style={{
                            fontWeight: "bold",
                            color: "green",
                            fontSize: "1.2rem",
                            textAlign: "center",
                          }}
                        >
                          Geöffnet
                        </div>
                      ) : (
                        <div
                          style={{
                            fontWeight: "bold",
                            color: "red",
                            fontSize: "1.2rem",
                            textAlign: "center",
                          }}
                        >
                          Geschlossen
                        </div>
                      )}
                      <ButtonBase
                        href="tel:060242220"
                        style={{
                          marginTop: 10,
                          fontSize: "1rem",
                          color: "#13294b",
                          width: "100%",
                        }}
                      >
                        Tel.:{" "}
                        <span style={{ fontWeight: "bold" }}>
                          &nbsp;06024/2220
                        </span>
                      </ButtonBase>
                    </div>
                  </div>
                </div>
              </Fade>
            )}
          </div>
        </div>
      </Slide>
    );
  } else {
    return null;
  }
}
